import React from 'react';
import Card from "./Card"

import './BlogSeries.scss';

const BlogSeries = ({posts, seriesName, currentPosts}) => {

  return (
    <div className="BlogSeriesContainer">
      <Card theme="Light" padding="Double" className="BlogSeries">
        <h2 className="BlogSeriesHeading">{seriesName} ({posts.totalCount} Part Series)</h2>
        <ol className="BlogSeriesList">
          {posts.nodes.map(post => (
            <li key={post.slug.current}>
              {currentPosts === post.title
                ? <span className="BlogSeriesCurrentPost">{post.title}</span>
                : <a href={`/blog/${post.slug.current}/`}>{post.title}</a>
              }
            </li>
          ))}
        </ol>
      </Card>
    </div>
  )
};

export default BlogSeries;
