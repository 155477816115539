import React from 'react';
import { graphql } from 'gatsby';

import PancakeIntro from '../PancakeIntro';
import Pancake from './Pancake';
import Container from '../Container';
import BlogItem from '../BlogItem';
import Button from '../Button';

import './BlogPostsListing.scss';

const BlogPostsListing = (props) => {
  const { title, text, posts, showCTA, theme, callToAction } = props;

  return (
    <Pancake className="BlogPostsListing" theme={theme}>
      <Container>
        {(title || text) && <PancakeIntro title={title} text={text} />}
        {posts?.length > 0 && (
          <div className="BlogPostsListingGrid">
            {posts.map((post, index) => {
              return <BlogItem post={post} key={index} />;
            })}
          </div>
        )}
        {showCTA && (
          <div className="BlogPostsListingCTA">
            {callToAction ? (
              <Button
                to={callToAction.url}
                className="corporate-light with-shadow"
              >
                {callToAction.label}
              </Button>
            ) : (
              <Button to="/blog/" className="corporate-light with-shadow">
                Read more
              </Button>
            )}
          </div>
        )}
      </Container>
    </Pancake>
  );
};

export const query = graphql`
  fragment SanityBlogPostsListing on SanityBlogPostsListing {
    _key
    _type
    theme
    pancakeIntro {
      pancakeText
      pancakeTitle
    }
    blogPosts {
      title
      date(formatString: "MMMM DD, YYYY")
      slug {
        current
      }
      authors {
        name
        image {
          ...ImageWithPreview
        }
      }
      _rawText(resolveReferences: { maxDepth: 1000 })
      textMarkdown
      excerpt
      featuredImage {
        altText
        image {
          ...ImageWithPreview
        }
      }
    }
  }
`;

export default BlogPostsListing;
