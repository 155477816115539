import React from 'react';
import TwitterImage from '../assets/twitter.svg';
import LinkedInImage from '../assets/linkedin.svg';

import './Share.scss';

function ShareIcon({ shareUrl, children }) {
  return (
    <a href={shareUrl} target="_blank" rel="noopenner noreferrer">
      {children}
    </a>
  )
}

function Share({ twitter, linkedin, href }) {
  return (
    <div className="Share">
      <h4>Share this:</h4>
      {twitter && (
        <ShareIcon shareUrl={`https://twitter.com/intent/tweet?url=${href}`}>
          <TwitterImage />
        </ShareIcon>
      )}
      {linkedin && (
        <ShareIcon
          shareUrl={`https://www.linkedin.com/sharing/share-offsite/?url=${href}`}
        >
          <LinkedInImage />
        </ShareIcon>
      )}
    </div>
  )
}

export default Share;
